import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { connect } from 'react-redux';
import { forEach, pick } from 'lodash';
import moment from 'moment';

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import DownloadCSVButton from '../components/download-csv';
import ClientSearch from '../components/client-search';
import { apiFetch } from '../lib/fetch';
import {
  Button,
  MutedTypography,
} from '../lib/styles';
import states from '../lib/states';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    minWidth: '300px',
    width: '80%',
  },
  form: {
    width: '80%',
    margin: 'auto',
  },
  saveBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px',
  },
  w200: {
    width: '200px',
  },
  notes: {
    width: '400px',
    margin: '20px',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  error: {
    color: 'red',
    fontSize: '1.5em',
    margin: '20px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'full_business_split',
    headerName: 'SH Business',
    align: 'right',
    width: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 150,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
  {
    field: 'account_num',
    headerName: 'Acc. Num',
    align: 'right',
    width: 90,
    renderCell: (params) => {
      if (params.row.account_num) {
        return 'Y';
      }
      return <span style={{ color: 'red' }}>N</span>;
    },
  },
  {
    field: 'routing_num',
    headerName: 'Routing',
    align: 'right',
    width: 90,
    renderCell: (params) => {
      if (params.row.routing_num) {
        return 'Y';
      }
      return <span style={{ color: 'red' }}>N</span>;
    },
  },
];

const chargebackColumns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'right',
    width: 180,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
];

const ecaColumns = [
  { field: 'id', hide: true },
  {
    field: 'commission',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{Number(params.row.commission).toFixed(2)}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 180,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
];

const shPaymentColumns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 180,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
];

const shPortionColumns = [
  { field: 'id', hide: true },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'right',
    width: 180,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
];

const logColumns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'right',
    width: 150,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      const { created_at } = params.row;
      return <span>{moment(created_at).format('YYYY-MM-DD h:mma')}</span>;
    },
  },
  {
    field: 'notes',
    headerName: 'Notes',
    align: 'right',
    width: 250,
  },
];

const BSF = () => {
  const { bsfId } = useParams();
  const history = useHistory();

  const [policy, setPolicy] = useState();
  const [agents, setAgents] = useState([]);
  const [payments, setPayments] = useState([]);
  const [chargebacks, setChargebacks] = useState([]);
  const [writingAgent, setWritingAgent] = useState();
  const [carriers, setCarriers] = useState();
  const [client, setClient] = useState();
  const [sources, setSources] = useState();
  const [productTypes, setProductTypes] = useState();
  const [error, setError] = useState();
  const [log, setLog] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [shPayments, setShPayments] = useState([]);
  const [ecaUploads, setEcaUploads] = useState([]);
  const [shPortions, setShPortions] = useState(0);
  const [canRollback, setCanRollback] = useState(false);
  const type = policy?.type;

  const findAgents = (policy) => {
    const result = [];
    forEach(policy, (partner) => {
      if (partner.agent_email === partner.email) {
        return;
      }
      const {
        agent, bsf_id, id, email, split,
      } = partner;
      return result.push({
        agent,
        bsf_id,
        id,
        email,
        split,
      });
    });
    setAgents(result);
  };

  useEffect(async () => {
    const res = await apiFetch(`/bsf/${bsfId}/detailso`);
    const { details } = res;
    delete res.details;
    setPolicy(res);
    findAgents(details.splits);
    setWritingAgent(details.user);
    setPayments(details.payments);
    setLog(details.logs);
    setClient(details.client);
    setChargebacks(details.chargebacks);
    setShPayments(details.shPayments);
    setEcaUploads(details.eca);
    setShPortions(details.shPortions);
    let canMaybeRollback = res.status !== 'OPEN';
    details.payments.forEach((payment) => {
      if (payment.status !== 'OPEN') {
        canMaybeRollback = false;
      }
    });
    details.shPayments.forEach((payment) => {
      if (payment.status !== 'OPEN') {
        canMaybeRollback = false;
      }
    });
    details.shPortions.forEach((portion) => {
      if (portion.status !== 'OPEN') {
        canMaybeRollback = false;
      }
    });
    setCanRollback(canMaybeRollback);
    setCarriers(await apiFetch('/carriers?orderBy=name&sortOrder=asc&active=true'));
    setProductTypes(await apiFetch('/product_types?orderBy=name&sortOrder=asc&active=true'));
    setSources(await apiFetch('/sources?orderBy=name&sortOrder=asc&active=true'));
  }, []);

  const handleReopen = async () => {
    // eslint-disable-next-line
    if (window.confirm('Are you sure you want to re-open this BSF?  Payments, chargebacks, and wealthshare contributions will be removed.')) {
      try {
        await apiFetch(`/bsf/${bsfId}/reopen`, { method: 'PUT', body: {} });
        document.location.reload();
      } catch (err) {
        setError(err.toString());
      }
    }
  };

  const updatePolicy = async () => {
    const pUpdate = pick(policy, [
      'annuity',
      'aum',
      'carrier_id',
      'first_distribution',
      'life_annual',
      'life_target',
      'personal',
      'state',
      'source_id',
      'policy_number',
      'product_type_id',
      'notes',
      'contract_basis',
      'additional_c_basis',
    ]);
    pUpdate.client_id = client.id;
    pUpdate.policy_number = String(pUpdate.policy_number).trim();
    pUpdate.notes = pUpdate.notes || '';
    if (pUpdate.aum) {
      pUpdate.aum = Number(pUpdate.aum);
    } else {
      delete pUpdate.aum;
    }
    if (pUpdate.annuity) {
      pUpdate.annuity = Number(pUpdate.annuity);
    } else {
      delete pUpdate.annuity;
    }
    if (pUpdate.life_target) {
      pUpdate.life_target = Number(pUpdate.life_target);
    } else {
      delete pUpdate.life_target;
    }

    try {
      await apiFetch(`/bsf/${bsfId}`, {
        method: 'PUT',
        body: pUpdate,
      });
      history.push('/bsfs');
    } catch (err) {
      setError(err.toString());
    }
  };

  const deletePolicy = async () => {
    try {
      await apiFetch(`/bsf/${bsfId}`, {
        method: 'DELETE',
      });
      history.push('/bsfs');
    } catch (err) {
      setError(err.toString());
    }
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="BSF Details">
      <div className={classes.container}>
        {error && (
          <div className={classes.error}>{error}</div>
        )}
        {policy && carriers && sources && writingAgent ? (
          <>
            <MutedTypography variant="h4">Writing Agent: {writingAgent.email} (#{writingAgent.num})</MutedTypography>
            <form className={classes.form}>
              <Box
                fullWidth
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  gap: 1,
                  gridTemplateRows: 'auto',
                  gridTemplateAreas: `
                  "header header header header"
                  "row1 row1 row1 row1"
                  "row2 row2 row2 row2"
                  "row3 row3 row3 row3"
                  "row4 row4 row4 row4"
                `,
                }}
              >
                <Box sx={{
                  gridArea: 'row1',
                  display: 'flex',
                  gap: 2,
                  marginBottom: 4,
                }}
                >
                  <TextField
                    disabled
                    label="Submitted On"
                    value={moment(policy.submitted_at).format('MM/DD/YYYY')}
                    name="submitted_at"
                    renderInput={(params) => <TextField className={classes.w200} {...params} />}
                  />
                  <ClientSearch
                    defaultValue=""
                    value={client}
                    agentId={writingAgent.id}
                    onChange={setClient}
                  />
                </Box>
                <Box sx={{
                  gridArea: 'row2',
                  display: 'flex',
                  gap: 2,
                  marginBottom: 4,
                }}
                >
                  <FormControl className={classes.w200} >
                    <TextField
                      select
                      labelId="carrier-simple-select-label"
                      label="Carrier"
                      value={policy.carrier_id}
                      name="carrier_id"
                      onChange={(evt) => {
                        setPolicy({ ...policy, carrier_id: evt.target.value });
                      }}
                    >
                      {carriers
                        .filter((carrier) => carrier.active === true)
                        .map((carrier) => <MenuItem value={carrier.id} key={carrier.id}>{carrier.name}</MenuItem>)}
                    </TextField>
                  </FormControl>
                  {type !== 'HEDGEHOG' ? (
                    <FormControl className={classes.w200} >
                      <TextField
                        select
                        labelId="product_type_id-simple-select-label"
                        label="Product"
                        value={policy.product_type_id}
                        name="product_type_id"
                        onChange={(evt) => {
                          setPolicy({ ...policy, product_type_id: evt.target.value });
                        }}
                      >
                        {productTypes
                          .filter((pt) => pt.active === true)
                          .map((pt) => <MenuItem value={pt.id} key={pt.id}>{pt.name}</MenuItem>)}
                      </TextField>
                    </FormControl>
                  ) : ''}
                  <FormControl required className={classes.w200} >
                    <TextField
                      labelId="demo-simple-select-label"
                      label="Client's State"
                      value={policy.state}
                      name="state"
                      onChange={(evt) => {
                        setPolicy({ ...policy, state: evt.target.value });
                      }}
                      select
                    >
                      {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
                    </TextField>
                  </FormControl>
                </Box>
                <Box sx={{
                  gridArea: 'row3',
                  display: 'flex',
                  gap: 2,
                  marginBottom: 4,
                }}
                >
                  <TextField
                    label={type === 'HEDGEHOG' ? 'Contract ID' : 'Policy Number'}
                    defaultValue=""
                    value={policy.policy_number}
                    name="policy_number"
                    sx={{ flexGrow: 1 }}
                    onChange={(evt) => {
                      setPolicy({ ...policy, policy_number: evt.target.value });
                    }}
                  />
                  <FormControl className={classes.w200} >
                    <TextField
                      select
                      labelId="source-simple-select-label"
                      label="Source"
                      value={policy.source_id}
                      name="source_id"
                      onChange={(evt) => {
                        setPolicy({ ...policy, source_id: evt.target.value });
                      }}
                    >
                      {sources
                        .filter((source) => source.active === true)
                        .map((source) => <MenuItem value={source.id} key={source.id}>{source.name}</MenuItem>)}
                    </TextField>
                  </FormControl>
                  <TextField
                    label="First Year Income Distribution $"
                    defaultValue=""
                    type="number"
                    value={policy.first_distribution}
                    name="first_distribution"
                    sx={{ flexGrow: 1 }}
                    onChange={(evt) => {
                      setPolicy({ ...policy, first_distribution: evt.target.value });
                    }}
                  />
                </Box>
                <Box sx={{
                  gridArea: 'row4',
                  display: 'flex',
                  gap: 2,
                  marginBottom: 4,
                }}
                >
                  {type === 'HEDGEHOG' ? (
                    <>
                      <TextField
                        label="Contract Basis"
                        defaultValue=""
                        type="number"
                        value={policy.contract_basis}
                        name="contract_basis"
                        sx={{ flexGrow: 1 }}
                        onChange={(evt) => {
                          setPolicy({ ...policy, contract_basis: evt.target.value });
                        }}
                      />
                      <TextField
                        label="Additional Contract Basis"
                        defaultValue=""
                        type="number"
                        value={policy.additional_c_basis}
                        name="additional_c_basis"
                        sx={{ flexGrow: 1 }}
                        onChange={(evt) => {
                          setPolicy({ ...policy, additional_c_basis: evt.target.value });
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {type === 'LIFE' ? (
                        <>
                          <TextField
                            label="Projected Target Premium"
                            defaultValue=""
                            type="number"
                            value={policy.life_target}
                            name="life_target"
                            sx={{ flexGrow: 1 }}
                            onChange={(evt) => {
                              setPolicy({ ...policy, life_target: evt.target.value });
                            }}
                          />
                          <TextField
                            label="Death Benefit"
                            defaultValue=""
                            type="number"
                            value={policy.death_benefit}
                            name="death_benefit"
                            sx={{ flexGrow: 1 }}
                            onChange={(evt) => {
                              setPolicy({ ...policy, death_benefit: evt.target.value });
                            }}
                          />
                        </>
                      ) : (
                        <TextField
                          label="Projected Annuity Amount"
                          defaultValue=""
                          type="number"
                          value={policy.life_annual}
                          name="life_annual"
                          sx={{ flexGrow: 1 }}
                          onChange={(evt) => {
                            setPolicy({ ...policy, life_annual: evt.target.value });
                          }}
                        />
                      )}
                    </>
                  )};
                </Box>
              </Box>
              <Box
                fullWidth
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  gap: 1,
                  gridTemplateRows: 'auto',
                  gridTemplateAreas: `
                  "header header header header"
                  "row1 row1 row1 row1"
                  "row2 row2 row2 row2"
                `,
                }}
              >
                <Box
                  sx={{
                    gridArea: 'row1',
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: 'white',
                  }}
                >
                  <MutedTypography variant="subtitle1">Is this a personal or spousal policy?</MutedTypography>
                  <RadioGroup
                    aria-label="personal-policy"
                    name="personal"
                    value={policy.personal}
                    onChange={(evt) => {
                      setPolicy({ ...policy, personal: (evt.target.value === 'true') });
                    }}
                    row
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </Box>
              </Box>
              {
              agents.length
                ? (
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(5, 1fr)',
                      gap: 1,
                      gridTemplateRows: 'auto',
                      gridTemplateAreas: `
                  "header header header header header"
                  ". row1 row1 row1 row1"
                  ". row2 row2 row2 row2"
                  ". row3 row3 row3 row3"
                  ". row4 row4 row4 row4"
                `,
                    }}
                  >
                    <Box sx={{ gridArea: 'header' }}>
                      <MutedTypography variant="h5">Commission Splits</MutedTypography>
                    </Box>
                    <Box sx={{
                      gridArea: 'row1',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                      marginBottom: 4,
                    }}
                    >
                      <TextField
                        disabled
                        label={policy.email}
                        sx={{ flexGrow: 1 }}
                      />
                      <TextField
                        disabled
                        label="Split percentage"
                        type="number"
                        value={policy.writing_agent_split}
                        className={classes.w200}
                      />
                    </Box>
                    <Box sx={{
                      gridArea: 'row2',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                      marginBottom: 4,
                    }}
                    >
                      <TextField
                        disabled
                        label={agents[0].email}
                        sx={{ flexGrow: 1 }}
                      />
                      <TextField
                        disabled
                        label="Split percentage"
                        type="number"
                        value={agents[0].split}
                        className={classes.w200}
                      />
                    </Box>
                    <Box sx={{
                      gridArea: 'row3',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                      marginBottom: 4,
                    }}
                    >
                      <TextField
                        disabled
                        label={agents[1] ? agents[1].email : 'N/A'}
                        sx={{ flexGrow: 1 }}
                      />
                      <TextField
                        disabled
                        label="Split percentage"
                        type="number"
                        value={agents[1] ? agents[1].split : 0}
                        className={classes.w200}
                      />
                    </Box>
                    <Box sx={{
                      gridArea: 'row4',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 2,
                      marginBottom: 4,
                    }}
                    >
                      <TextField
                        disabled
                        label={agents[2] ? agents[2].email : 'N/A'}
                        sx={{ flexGrow: 1 }}
                      />
                      <TextField
                        disabled
                        label="Split percentage"
                        type="number"
                        value={agents[2] ? agents[2].split : 0}
                        className={classes.w200}
                      />
                    </Box>
                  </Box>
                ) : ''
              }

              <div className={classes.saveBtnContainer}>
                <TextField
                  multiline
                  rows="5"
                  label="Notes"
                  value={policy.notes}
                  className={classes.notes}
                  onChange={(evt) => {
                    setPolicy({ ...policy, notes: evt.target.value });
                  }}
                />
              </div>

              <div>
                <Tabs
                  value={tabIndex}
                  onChange={(e, v) => {
                    setTabIndex(v);
                  }}
                  centered
                >
                  <Tab label="Payments"/>
                  <Tab label="Chargebacks"/>
                  <Tab label="Wealthshare Contr."/>
                  <Tab label="Comp Uploads"/>
                  <Tab label="SH Commission"/>
                </Tabs>
                <div style={{ display: tabIndex === 0 ? '' : 'none' }}>
                  <Box>
                    <DownloadCSVButton
                      rows={payments}
                      name={`${policy?.policy_number}-payments.csv`}
                    />
                  </Box>
                  <Paper>
                    <div className={classes.gridContainer}>
                      <DataGrid columns={columns} rows={payments || []} />
                    </div>
                  </Paper>
                </div>
                <div style={{ display: tabIndex === 1 ? '' : 'none' }}>
                  <Box>
                    <DownloadCSVButton
                      rows={chargebacks}
                      name={`${policy?.policy_number}-chargebacks.csv`}
                    />
                  </Box>
                  <Paper>
                    <div className={classes.gridContainer}>
                      <DataGrid columns={chargebackColumns} rows={chargebacks || []} />
                    </div>
                  </Paper>
                </div>
                <div style={{ display: tabIndex === 2 ? '' : 'none' }}>
                  <Box>
                    <DownloadCSVButton
                      rows={shPayments}
                      name={`${policy?.policy_number}-wealthsare-contributions.csv`}
                    />
                  </Box>
                  <Paper>
                    <div className={classes.gridContainer}>
                      <DataGrid columns={shPaymentColumns} rows={shPayments || []} />
                    </div>
                  </Paper>
                </div>
                <div style={{ display: tabIndex === 3 ? '' : 'none' }}>
                  <Box>
                    <DownloadCSVButton
                      rows={ecaUploads}
                      name={`${policy?.policy_number}-eca-uploads.csv`}
                    />
                  </Box>
                  <Paper>
                    <div className={classes.gridContainer}>
                      <DataGrid columns={ecaColumns} rows={ecaUploads || []} />
                    </div>
                  </Paper>
                </div>
                <div style={{ display: tabIndex === 4 ? '' : 'none' }}>
                  <Box>
                    <DownloadCSVButton
                      rows={shPortions}
                      name={`${policy?.policy_number}-stronghold-commission.csv`}
                    />
                  </Box>
                  <Paper>
                    <div className={classes.gridContainer}>
                      <DataGrid columns={shPortionColumns} rows={shPortions || []} />
                    </div>
                  </Paper>
                </div>
              </div>

              {log?.length ? (
                <div>
                  <Box>
                    <MutedTypography variant="h6">History</MutedTypography>
                  </Box>
                  <Paper>
                    <div className={classes.gridContainer}>
                      <DataGrid columns={logColumns} rows={log || []} />
                    </div>
                  </Paper>
                </div>
              ) : ''}

              <div className={classes.saveBtnContainer}>
                {policy.status !== 'CLOSED' && (payments && payments.length === 0) ? (
                  <Button
                    gold
                    onClick={deletePolicy}
                    sx={{ width: '100px', marginRight: '100px' }}
                  >
                    Delete
                  </Button>
                ) : ''}
                {policy.status !== 'CLOSED' ? (
                  <Button
                    gold
                    onClick={updatePolicy}
                    disabled={!client}
                    sx={{ width: '100px', marginRight: '100px' }}
                  >
                    Update
                  </Button>
                ) : ''}
                {canRollback ? (
                  <Button
                    gold
                    onClick={handleReopen}
                    sx={{ width: '100px', marginRight: '100px' }}
                  >
                    Re-Open
                  </Button>
                ) : ''}
                <Button
                  gold
                  onClick={() => history.goBack()}
                  sx={{ width: '100px' }}
                >
                  Go Back
                </Button>
              </div>
            </form>
          </>
        ) : <CircularProgress/>}
      </div>
    </Layout>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps)(BSF);
